<template>
    <dx-util-popup
    ref="packageReceiveBatchPopupRef"
    :show-close-button="true"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-title="true"
    :full-screen="false"
    width="35vw"
    height="auto"
    :title="packageReceiveBatchPopupTitle"
    @shown="onShownPackageReceiveBatchPopup"
    @showing="onShowingPackageReceiveBatchPopup"
    @hidden="onHiddenPackageReceiveBatchPopup"
  >
    <dx-util-validation-group v-show="!changeStatus" ref="packageReceiveBatchFormRef">
      <dx-util-text-box v-model="packageReceiveBatchForm.batchName" label="Batch Name"
        label-mode="floating" :show-clear-button="true"
        class="my-1"
      >
        <dx-util-validator>
          <dx-util-required-rule message="Batch name is required" />
        </dx-util-validator>
      </dx-util-text-box>
      <dx-util-select-box v-model="packageReceiveBatchForm.warehouse" :data-source="warehouses"
        display-expr="text" value-expr="value"
        label="Warehouse" label-mode="floating"
        class="my-1"
      >
        <dx-util-validator>
          <dx-util-required-rule message="Warehouse is required" />
        </dx-util-validator>
      </dx-util-select-box>
      <div class="d-flex my-1">
        <div class="col pl-0 pr-1">
          <dx-util-select-box v-model="packageReceiveBatchForm.carrierType" :data-source="carrierTypeOptions"
            display-expr="text" value-expr="value"
            label="Carrier Type" label-mode="floating"
            @value-changed="getCarriersByType"
          >
            <dx-util-validator>
              <dx-util-required-rule message="Carrier type is required" />
            </dx-util-validator>
          </dx-util-select-box>
        </div>
        <div class="col pl-1 pr-0">
          <dx-util-select-box v-model="packageReceiveBatchForm.carrier" :data-source="carrierOptions"
            display-expr="text" value-expr="value"
            label="Carrier" label-mode="floating"
          >
          </dx-util-select-box>
        </div>
      </div>
      <div class="d-flex my-1">
        <div class="col pl-0 pr-1">
          <dx-util-number-box v-model="packageReceiveBatchForm.expectedCount"
            label="Expected Count" label-mode="floating"
            :min="0"
          >
            <dx-util-validator>
              <dx-util-required-rule message="Expected count is required" />
            </dx-util-validator>
          </dx-util-number-box>
        </div>
        <div class="col pl-1 pr-0">
          <dx-util-number-box v-model="packageReceiveBatchForm.receivedCount"
            label="Received Count" label-mode="floating"
            :read-only="true"
          >
            <dx-util-validator>
              <dx-util-required-rule message="Carrier is required" />
            </dx-util-validator>
          </dx-util-number-box>
        </div>
      </div>
      <dx-util-text-area v-model="packageReceiveBatchForm.notes" :max="250" label-mode="floating" label="Notes" />
    </dx-util-validation-group>
    <div v-show="changeStatus" class="my-1">
      <dx-radio-group
        v-model="packageReceiveBatchForm.status"
        :data-source="statusOptions"
        layout="horizontal"
        display-expr="text"
        value-expr="value"
      />
    </div>
    <div class="d-flex p-1 justify-content-end">
      <dx-util-button type="danger" text="Cancel" class="mr-1" style="min-width:100px" @click="onClose" />
      <dx-util-button type="success" text="Save" class="" style="min-width:100px" @click="onSubmit" />
    </div>
  </dx-util-popup>
</template>

<script>
import { Notify } from '@robustshell/utils/index'
import packageReceiveBatchService from '@/http/requests/inbound/packageReceiveBatchService'
import carriersService from '@/http/requests/common/carriersService'
import shipService from '@/http/requests/ship/shipService'
import DxRadioGroup from 'devextreme-vue/radio-group'

export default {
  components: {
    'dx-radio-group': DxRadioGroup,
  },
  props: {
    selectedRow: {
      type: Object,
      required: true,
    },
    changeStatus: {
      type: Boolean,
      required: true,
      default: false,
    },
    componentId: {
      type: String,
      default: '',
    },
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      packageReceiveBatchPopupTitle: '',
      packageReceiveBatchForm: {
        id: '',
        batchId: '',
        batchName: '',
        companyId: '',
        carrier: '',
        carrierType: '',
        expectedCount: 0,
        receivedCount: 0,
        warehouse: 0,
        status: '',
        notes: '',
      },
      blankForm: {
        id: '',
        batchId: '',
        batchName: '',
        companyId: '',
        carrier: '',
        carrierType: '',
        expectedCount: 0,
        receivedCount: 0,
        warehouse: 0,
        status: '',
        notes: '',
      },
      carrierTypeOptions: [
        { text: 'Parcel', value: 'parcel' },
        { text: 'LTL', value: 'ltl' },
        { text: 'Container', value: 'container' },
      ],
      carrierOptions: [],
      statusOptions: [
        { text: 'Open', value: 'open' },
        { text: 'Complete', value: 'complete' },
        { text: 'Missing', value: 'missing' },
      ],
      warehouses: [],
    }
  },
  computed: {
    packageReceiveBatchPopupIns() {
      return this.$refs.packageReceiveBatchPopupRef.instance
    },
    packageReceiveBatchFormIns() {
      return this.$refs.packageReceiveBatchFormRef.instance
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler(current) {
        if (current) {
          this.createReceivePackageBatchPopup()
        }
      },
    },
  },
  mounted() {
    this.getWarehouses()
  },
  methods: {
    createReceivePackageBatchPopup() {
      this.packageReceiveBatchForm.companyId = this.selectedRow.companyId
      this.$nextTick(() => {
        this.packageReceiveBatchPopupIns.show()
      })
    },
    onShowingPackageReceiveBatchPopup() {
      if (this.id) {
        this.packageReceiveBatchForm.batchId = this.selectedRow.batchId
        this.packageReceiveBatchForm.batchName = this.selectedRow.batchName
        this.packageReceiveBatchForm.warehouse = this.selectedRow.warehouseId
        this.packageReceiveBatchForm.carrierType = this.selectedRow.carrierType
        this.packageReceiveBatchForm.expectedCount = this.selectedRow.expectedCount
        this.packageReceiveBatchForm.receivedCount = this.selectedRow.receivedCount
        this.packageReceiveBatchForm.notes = this.selectedRow.notes
        this.packageReceiveBatchForm.companyId = this.selectedRow.companyId
        this.packageReceiveBatchForm.status = this.selectedRow.status
        this.packageReceiveBatchForm.id = this.id
        this.packageReceiveBatchForm.notes = this.selectedRow.notes
      }
    },
    onShownPackageReceiveBatchPopup() {
      this.packageReceiveBatchPopupTitle = this.id ? `Update Record #${this.id} - ${this.selectedRow.batchName}` : 'Create New'
    },
    onHiddenPackageReceiveBatchPopup() {
      this.resetForm()
    },
    onClose() {
      this.packageReceiveBatchPopupIns.hide()
    },
    resetForm() {
      Object.assign(this.packageReceiveBatchForm, this.blankForm)
      this.packageReceiveBatchFormIns.reset()
    },
    getCarriersByType(type) {
      if (!type.value) return
      carriersService.fetchByType(type.value).then(result => {
        this.carrierOptions = []
        if (result.data.length > 0) {
          result.data.forEach(element => {
            this.carrierOptions.push({ text: element.name, value: element.id })
          })
        }
        // this.selectedCarrier = 0
      })
    },
    getWarehouses() {
      shipService.fetchWarehouses().then(result => {
        result.data.forEach(element => {
          if (element.name.toLowerCase() !== 'common') {
            this.warehouses.push({ value: element.id, text: element.name })
          }
        })
        if (this.warehouses.length === 1) {
          // this.selectedWarehouse = result.data[0].id
          this.packageReceiveBatchForm.warehouse = this.warehouses[0].value
        }
      })
    },
    onSubmit(e) {
      if (this.packageReceiveBatchForm.receivedCount > this.packageReceiveBatchForm.expectedCount) {
        Notify.warning('Received count cannot be grater that expected count.')
        return
      }
      const validationResult = e.validationGroup.validate()
      if (validationResult.isValid) {
        if (this.id === 0) {
          packageReceiveBatchService.create(this.packageReceiveBatchForm).then(() => {
            this.$emit('on-item-saved')
            this.onClose()
          })
        }
        if (this.id > 0 && !this.changeStatus) {
          packageReceiveBatchService.update(this.packageReceiveBatchForm).then(() => {
            this.$emit('on-item-saved')
            this.onClose()
          })
        }
        if (this.id > 0 && this.changeStatus) {
          const params = {
            id: this.packageReceiveBatchForm.id,
            status: this.packageReceiveBatchForm.status,
          }
          packageReceiveBatchService.changeStatus(params).then(() => {
            this.$emit('on-item-saved')
            this.onClose()
          })
        }
      }
    },
  },
}
</script>
