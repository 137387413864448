import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  async fetchAll() {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.carriers.Controller.findAll())
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async fetchByType(type) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.carriers.Controller.findAllByType(type))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  // create prep material inventory
  async create(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.carriers.Controller.create(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
}
