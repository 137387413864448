import axios from '@axios'
import endpoints from '@/http/endpoints/index'

export default {
  async fetchAll(pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios
        .get(endpoints.packageReceiveBatch.Controller.findAll(pageable))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async fetchById(id) {
    const promise = new Promise((resolve, reject) => {
      axios.get(endpoints.packageReceiveBatch.Controller.findById(id))
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  fetchByQuery(filters, pageable = {}) {
    const promise = new Promise((resolve, reject) => {
      axios.post(endpoints.packageReceiveBatch.Controller.getByQuery(pageable), filters)
        .then(response => {
          resolve({
            data: response.data.body,
          })
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async changeStatus(params) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.packageReceiveBatch.Controller.changeStatus(params))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })
    promise.then(
      result => result,
      error => error,
    )
    return promise
  },

  async create(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .post(endpoints.packageReceiveBatch.Controller.create(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async update(data) {
    const promise = new Promise((resolve, reject) => {
      axios
        .put(endpoints.packageReceiveBatch.Controller.update(), data)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async deleteById(id) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.packageReceiveBatch.Controller.delete(id))
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },

  async deleteMany(idList) {
    const promise = new Promise((resolve, reject) => {
      axios
        .delete(endpoints.packageReceiveBatch.Controller.deleteMany(), idList)
        .then(response => {
          resolve(response)
        })
        .catch(error => reject(error))
    })

    promise.then(
      result => result,
      error => error,
    )

    return promise
  },
}
