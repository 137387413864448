import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import packageReceiveBatchService from '@/http/requests/inbound/packageReceiveBatchService'
import Pager from '@core/dev-extreme/utils/pager'

const packageReceiveBatchStore = new CustomStore({
  key: 'id',
  cacheRawData: false,
  // eslint-disable-next-line no-unused-vars
  load: async loadOptions => {
    const options = {}
    const sort = (loadOptions.sort && loadOptions.sort.length > 0) ? loadOptions.sort : [{ selector: 'id', desc: false }]
    const pager = new Pager(options, sort)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)
    pager.setSortOrder(sort)

    const pageableQuery = `${pager.toCreatePageable()}`
    const filters = (loadOptions.searchValue) ? loadOptions.searchValue : null
    const response = await packageReceiveBatchService.fetchByQuery(filters, pageableQuery)
    const data = response.data
    return {
      data: data.content,
      totalCount: data.totalElements,
    }
  },
})

const packageReceiveBatchDataSource = new DataSource({
  store: packageReceiveBatchStore,
})

export {
  packageReceiveBatchDataSource,
}
