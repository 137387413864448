<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 px-0">
        <dx-data-grid
          id="packageReceiveBatchGrid"
          ref="packageReceiveBatchGridRef"
          :data-source="packageReceiveBatchDataSource"
          :height="setHeight"
          :cache-enabled="false"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :hover-state-enabled="true"
          :row-alternation-enabled="false"
          @initialized="onInitialized"
          @content-ready="onContentReady"
          @toolbar-preparing="onToolbarPreparing"
          @editor-preparing="onEditorPreparing"
        >
          <!--region    ::DataGird base components -->
          <dx-sorting mode="single" />
          <dx-remote-operations :paging="true" :filtering="false" :summary="false" />
          <dx-search-panel :highlight-search-text="true" :visible="true" :search-visible-columns-only="true" :width="200" />
          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
          <!--endregion ::DataGird base components -->
                    <!--region    ::DataGird button columns -->
          <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="240" :fixed="true" fixed-position="left" />
          <template #itemActions="{data}">
            <div>
              <dx-util-button
                type="default" icon="bi bi-card-list"
                class="mr-half" hint="Batch Item List"
                @click="onClickCatchItems(data)"
              />
              <dx-util-button :visible="hasPermission"
                type="aqua" icon="bi bi-box-arrow-in-down-right"
                class="mr-half" hint="Join Batch"
                @click="onClickJoinBatch(data)"
              />
              <dx-util-button :visible="hasPermission"
                type="warning" icon="bi bi-pencil-square"
                class="mr-half" hint="Edit"
                @click="onClickEdit(data)"
              />
              <dx-util-button :visible="hasPermission"
                type="success" icon="bi bi-check-circle-fill"
                class="mr-half" hint="Change Batch Status"
                @click="onClickChangeBatchStatus(data)"
              />
              <dx-util-button :visible="hasPermission"
                :disabled="allowDeleting(data)"
                type="danger" icon="bi bi-trash"
                hint="Delete"
                @click="onClickDelete(data)"
              />
            </div>
          </template>
          <!--endregion ::DataGird button columns -->
          <!--region    ::DataGird columns -->
          <dx-column data-field="batchName" />
          <dx-column data-field="companyName" caption="Company" :visible="isServingTenant" />
          <dx-column data-field="accountNo" caption="Acc #" :visible="isServingTenant" />
          <dx-column data-field="suiteNo" caption="Suite" :visible="isServingTenant" />
          <dx-column data-field="receivedCount" caption="Package QTY" alignment="right" />
          <dx-column data-field="status" caption="Status" alignment="center" cell-template="statusCellTemplate" />
          <template #statusCellTemplate="{ data }">
              <span class="badge" :class="`badge-${statusStyle(data.value)}`">{{ data.value | capitalize }}</span>
            </template>
          <dx-column data-field="creationTime" caption="Received At" data-type="date" />
          <dx-column data-field="warehouseName" caption="Warehouse" alignment="left" :visible="isServingTenant" />
          <!--endregion ::DataGird columns -->
          <!--region    ::DataGird toolbar -->
          <template #toolbarFilters>
            <div class="d-flex flex-row justify-content-start px-half">
              <div v-if="false && hasPermission" class="mr-1">
                <dx-util-text-box
                  v-model="accountNo"
                  :show-clear-button="true"
                  mode="text"
                  placeholder="Account or Suite No"
                  @key-down="onFilterByAccountNo"
                  @value-changed="onFilterByAccountNo"
                />
              </div>
              <div class="mr-1">
                <dx-util-select-box
                  v-model="selectedDateRange"
                  :data-source="dateRangesList"
                  :drop-down-options="{width: '180px'}"
                  display-expr="text"
                  value-expr="value"
                  @value-changed="setDateRanges"
                />
              </div>
              <div v-show="isCustomDateRange" class="col px-half">
                <dx-util-date-box
                  v-model="filters.beginDate"
                  :max="filters.endDate"
                  type="date"
                  @value-changed="searchByFilter"
                />
              </div>
              <div v-show="isCustomDateRange" class="col px-half">
                <dx-util-date-box
                  v-model="filters.endDate"
                  :min="filters.beginDate"
                  type="date"
                  @value-changed="searchByFilter"
                />
              </div>
            </div>
          </template>
          <!--endregion ::DataGird toolbar -->
        </dx-data-grid>
      </div>
      <div class="col-md-12 px-0">
        <package-receive-batch-createoredit
          :id="selectedItem.id"
          :selected-row="selectedItem"
          :change-status="isChangeStatus"
          :component-id="packageReceiveBatchComponentId"
          @on-item-saved="onRowChanged"
        />
      </div>
    </div>
  </div>
</template>

<script>
import predefinedDateRangesEnum, { getPredefinedDateRangesList } from '@/enums/predefinedDateRanges.enum.js'
import useDateRanges from '@/@core/composables/useDateRanges.js'
import useCurrentUser from '@/libs/app/current-user'
import GridBase from '@core/dev-extreme/mixins/grid/base'
import Filters from '@robustshell/mixins/filters'
import useConfirmation from '@/libs/app/confirmation'
import { v4 as uuidv4 } from 'uuid'
import { Notify } from '@robustshell/utils/index'
import ReceivePackageSortFilter from '@/http/models/search-filters/ReceivePackageSortFilter'
import packageReceiveBatchService from '@/http/requests/inbound/packageReceiveBatchService'
import { packageReceiveBatchDataSource } from './packageReveiveBatchStore'
import PackageReceiveBatchCreateOrEdit from './create-edit/PackageReceiveBatchCreateOrEdit.vue'

export default {
  components: {
    'package-receive-batch-createoredit': PackageReceiveBatchCreateOrEdit,
  },
  mixins: [GridBase, Filters],
  props: {
    // Bu üçü route param olduğundan string olarak geliyor.
    companyName: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { pshConfirm } = useConfirmation()
    const {
      userId,
      userWarehouseId,
      isServingTenant,
      isIndividualTenant,
    } = useCurrentUser()
    const { setDateRanges, startDate, isCustomDateRange } = useDateRanges()
    return {
      pshConfirm,
      userId,
      userWarehouseId,
      setDateRanges,
      startDate,
      isServingTenant,
      isCustomDateRange,
      isIndividualTenant,
    }
  },
  data() {
    const firstDayOfMonth = new Date()
    const plusOneDay = new Date()
    firstDayOfMonth.setDate(1)
    firstDayOfMonth.setHours(0, 0, 0, 1)
    plusOneDay.setDate(plusOneDay.getDate() + 1)
    return {
      packageReceiveBatchDataSource,
      filters: {
        query: '',
        status: 'all',
        beginDate: firstDayOfMonth,
        endDate: plusOneDay,
      },
      statuses: [
        { text: 'Status (All)', value: 'all' },
        { text: 'Open', value: 'open' },
        { text: 'Closed', value: 'complete' },
      ],
      selectedItem: {
        id: 0,
      },
      isChangeStatus: false,
      selectedDateRange: predefinedDateRangesEnum.CURRENTMONTH.value,
      dateRangesList: getPredefinedDateRangesList(),
      packageReceiveBatchComponentId: '',
      companyId: null,
    }
  },
  computed: {
    hasPermission() {
      return this.$can('read', 'resource_customer_account_no')
    },
    dataGrid() {
      const grid = this.$refs.packageReceiveBatchGridRef.instance
      return grid
    },
    warehouseId() {
      return this.userWarehouseId
    },
    dataSource() {
      const dataSource = this.dataGrid.getDataSource()
      return dataSource
    },
  },
  watch: {
    selectedDateRange() {
      const plusOneDay = new Date()
      plusOneDay.setDate(plusOneDay.getDate() + 1)
      this.filters.beginDate = this.startDate || this.filters.beginDate
    },
  },
  mounted() {
    const accNo = parseInt(this.$route.params.accountNo, 10)
    this.accountNo = accNo

    const compId = parseInt(this.$route.params.companyId, 10)
    this.companyId = compId
    this.loadData()
  },
  beforeDestroy() {
    this.dataGrid.dispose()
    this.dataGrid.remove()
  },
  methods: {
    allowDeleting(e) {
      return e.row.data.receivedCount > 0
    },
    loadData() {
      ReceivePackageSortFilter.setDefaultFilters()
      ReceivePackageSortFilter.accountNo = this.accountNo
      ReceivePackageSortFilter.warehouseId = this.warehouseId
      ReceivePackageSortFilter.storeId = this.selectedStore
      ReceivePackageSortFilter.status = this.filters.status
      ReceivePackageSortFilter.beginDate = this.filters.beginDate
      ReceivePackageSortFilter.endDate = this.filters.endDate
      ReceivePackageSortFilter.q = this.filters.query
      const filters = ReceivePackageSortFilter.getFilters()
      packageReceiveBatchDataSource.searchValue(filters)
      packageReceiveBatchDataSource.load()
    },
    reloadData() {
      ReceivePackageSortFilter.setDefaultFilters()
      ReceivePackageSortFilter.accountNo = this.accountNo
      ReceivePackageSortFilter.warehouseId = this.warehouseId
      ReceivePackageSortFilter.storeId = this.selectedStore
      ReceivePackageSortFilter.status = this.filters.status
      // this.selectedDateRange = predefinedDateRangesEnum.CURRENTMONTH.value
      const firstDayOfMonth = new Date()
      firstDayOfMonth.setDate(1)
      firstDayOfMonth.setHours(0, 0, 0, 1)
      this.filters.beginDate = firstDayOfMonth
      const plusOneDay = new Date()
      plusOneDay.setDate(plusOneDay.getDate() + 1)
      this.filters.endDate = plusOneDay
      ReceivePackageSortFilter.beginDate = this.filters.beginDate
      ReceivePackageSortFilter.endDate = this.filters.endDate
      ReceivePackageSortFilter.q = this.filters.query
      const filters = ReceivePackageSortFilter.getFilters()
      packageReceiveBatchDataSource.searchValue(filters)
      packageReceiveBatchDataSource.load()
    },
    onFilterByAccountNo(e) {
      if (e.event && e.event.keyCode === 13) {
        if (e.event.target.value !== '' && e.event.target.value !== null) {
          this.accountNo = e.event.target.value
          this.searchByFilter()
        }
      } else if ((!e.value && e.event && e.event.type && e.event.type !== 'keydown') || (e.event && e.event.keyCode === 27)) {
        this.accountNo = ''
        this.searchByFilter()
      }
    },
    searchByFilter() {
      ReceivePackageSortFilter.setDefaultFilters()
      ReceivePackageSortFilter.accountNo = this.accountNo
      ReceivePackageSortFilter.warehouseId = this.warehouseId
      ReceivePackageSortFilter.storeId = this.selectedStore
      ReceivePackageSortFilter.status = this.filters.status
      ReceivePackageSortFilter.beginDate = this.filters.beginDate
      ReceivePackageSortFilter.endDate = this.filters.endDate
      ReceivePackageSortFilter.q = this.filters.query
      const filters = ReceivePackageSortFilter.getFilters()
      packageReceiveBatchDataSource.searchValue(filters)
      packageReceiveBatchDataSource.reload()
    },
    async onRowChanged() {
      this.searchByFilter()
    },
    onToolbarPreparing(e) {
      const toolbarItems = e.toolbarOptions.items
      const tempToolbarItems = [...toolbarItems]
      toolbarItems.length = 0
      toolbarItems.unshift({
        location: 'before',
        template: 'toolbarFilters',
      })
      toolbarItems.push({
        widget: 'dxButton',
        visible: this.hasPermission || this.isIndividualTenant,
        options: {
          icon: 'add',
          text: 'Create New',
          type: 'default',
          hint: 'Create new unload batch',
          onClick: () => {
            this.onClickAdd()
          },
        },
        location: 'after',
      })
      toolbarItems.push({
        widget: 'dxButton',
        options: {
          icon: 'refresh',
          onClick: () => {
            this.reloadData()
          },
        },
        location: 'after',
      })
      tempToolbarItems.forEach(item => {
        toolbarItems.push(item)
      })
    },
    onEditorPreparing(e) {
      if (e.parentType === 'searchPanel') {
        e.editorOptions.onValueChanged = arg => {
          const query = arg.component.option('value')
          if (!query) {
            this.filters.query = query.trim()
            e.component.searchByText('')
            this.searchByFilter()
          }
        }
        e.editorOptions.onKeyDown = arg => {
          if (arg.event.keyCode === 13) {
            let query = arg.component.option('value')
            query = query.trim()
            arg.component.option('value', query)
            this.filters.query = query
            this.searchByFilter()
            e.component.searchByText(query)
          }
          if (arg.event.keyCode === 27) {
            e.component.searchByText('')
            arg.component.option('value', '')
            this.filters.query = ''
            this.searchByFilter()
          }
        }
      }
    },
    onClickCatchItems(e) {
      const data = e.row.data
      this.$router.push({
        name: 'route-package-scan',
        query: {
          id: data.id,
          batchId: data.batchId,
          batchName: encodeURIComponent(data.batchName),
          status: data.status,
          accountNo: data.accountNo,
          companyName: encodeURIComponent(data.companyName),
          companyId: data.companyId,
        },
      // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        // this.showError(error, 'Access')
      })
    },
    onClickJoinBatch(e) {
      const data = e.row.data
      if (data.status.toLowerCase() === 'complete') {
        this.$swal({
          icon: 'error',
          title: 'Error Joining Batch!',
          text: 'Cannot Join Completed Batch!',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        })
        return
      }
      this.$router.push({
        name: 'route-package-scan',
        query: {
          id: data.id,
          batchId: data.batchId,
          batchName: encodeURIComponent(data.batchName),
          status: data.status,
          accountNo: data.accountNo,
        },
      // eslint-disable-next-line no-unused-vars
      }).catch(error => {
        // this.showError(error, 'Access')
      })
    },
    onClickChangeBatchStatus(e) {
      this.selectedItem = { ...e.row.data }
      this.isChangeStatus = true
      // this.$root.$emit('bv::toggle::collapse', 'create-or-edit-sidebar')
      this.packageReceiveBatchComponentId = uuidv4()
    },
    onClickAdd() {
      this.selectedItem = {
        id: 0,
        accountNo: this.accountNo,
        companyId: this.companyId,
      }
      this.isChangeStatus = false
      // this.$root.$emit('bv::toggle::collapse', 'create-or-edit-sidebar')
      this.packageReceiveBatchComponentId = uuidv4()
    },
    onClickEdit(e) {
      this.selectedItem = { ...e.row.data }
      this.isChangeStatus = false
      this.packageReceiveBatchComponentId = uuidv4()
    },
    async onClickDelete(e) {
      const confirm = await this.pshConfirm(
        'Delete Item Confirmation',
        `<p>Are you sure you want to delete this batch?</p>
         <h4 class="text-danger mt-half">This action cannot be undone.</h4>`,
      )
      if (confirm) {
        try {
          await packageReceiveBatchService.deleteById(e.row.key)
          await this.onRowChanged()
        } catch (error) {
          Notify.error(error)
        }
      }
    },
    normalizeText(cellInfo) {
      return this.$options.filters.capitalize(cellInfo.valueText)
    },
    statusStyle(status) {
      if (status.toLowerCase() === 'open') return 'warning'
      if (status.toLowerCase() === 'complete') return 'success'
      if (status.toLowerCase() === 'missing') return 'danger'
      return 'primary'
    },
  },
}
</script>

<style lang="scss"></style>
